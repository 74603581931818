import React from 'react';

import Layout from '../components/organisms/layout';
import SEO from '../components/organisms/seo';

const NotFoundPage = () => (
  <Layout>
    <SEO siteTitle="404" />
    <p style={{ margin: 'var(--v-spacing-S)' }}>
      Diese Seite gibt es leider nicht.
    </p>
  </Layout>
);

export default NotFoundPage;
